var pomlo_app = {};

// Define constants
pomlo_app.BREAKPOINTS = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1140
};
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.HEADER_HEIGHT = 0;

// Function to define header style (default/small)
pomlo_app.toggleHeaderStyle = function() {

    function setSmallHeader() {
        jQuery('body').addClass('header--small');
        jQuery('#navbar-header').addClass('navbar__header--small');
        jQuery('#main-menu').addClass('main-menu--small');
    }

    function unsetSmallHeader() {
        jQuery('body').removeClass('header--small');
        jQuery('#navbar-header').removeClass('navbar__header--small');
        jQuery('#main-menu').removeClass('main-menu--small');
    }

    // Manage pre-header
    if (jQuery('#navbar-top-header').length) {
        if (jQuery(window).scrollTop() > pomlo_app.HEADER_HEIGHT) {
            jQuery('#navbar-top-header').addClass('navbar__top-header--transparent');
        } else {
            jQuery('#navbar-top-header').removeClass('navbar__top-header--transparent');
        }
    }

    // On mobile device or if header is locked to small class
    if ((jQuery(window).width() < pomlo_app.BREAKPOINTS.MD ) || (jQuery('#navbar-header').hasClass("navbar__header--small--lock"))) {
        setSmallHeader();
        return;
    }

    if (jQuery(window).scrollTop() > pomlo_app.HEADER_HEIGHT) {
        setSmallHeader();
        jQuery('#navbar').addClass('navbar--opaque');
    } else {
        unsetSmallHeader();
        jQuery('#navbar').removeClass('navbar--opaque');
    }
};

// Function to scroll to a page section
pomlo_app.scrollTo = function(selector) {
    jQuery(selector).animatescroll( {
        scrollSpeed: 600,
        easing: 'easeInOutQuad'
    });
};

// Observer and animation functions
pomlo_app.observeSection = function(selector, func, options) {
    var section = document.querySelector(selector);

    var sectionObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.intersectionRatio > 0) {
                func(options);
                observer.unobserve(entry.target);
            }
        });
    });

    if (section) {
        sectionObserver.observe(section);
    }
};

pomlo_app.animateHomeIntroImage = function(selector) {
    jQuery(selector).addClass('animate__animated animate__fadeIn');
};

pomlo_app.animateHomeFadeInLeft = function(selector) {
    jQuery(selector).addClass('animate__animated animate__fadeInLeft');
};

pomlo_app.animateHomeFadeInRight = function(selector) {
    jQuery(selector).addClass('animate__animated animate__fadeInRight');
};

jQuery(document).ready(function($) {
    // Init interface

    pomlo_app.toggleHeaderStyle();

    // Setup intersection observers to trigger actions
    pomlo_app.observeSection('#home-intro-image', pomlo_app.animateHomeIntroImage, '#home-intro-image');
    pomlo_app.observeSection('#home-intro-left', pomlo_app.animateHomeFadeInLeft, '#home-intro-left');
    pomlo_app.observeSection('#home-intro-right', pomlo_app.animateHomeFadeInRight, '#home-intro-right');

    pomlo_app.observeSection('#home-news__items', pomlo_app.animateHomeFadeInLeft, '#home-news__items');
    pomlo_app.observeSection('#home-news__introduction', pomlo_app.animateHomeFadeInRight, '#home-news__introduction');

    // Loader
    jQuery('.img-loader-wait').imagesLoaded(function() {
        jQuery('.img-bg-loader-wait').imagesLoaded( { background: true }, function() {
            console.log('Background image loaded');

            var delay = document.location.host ? 0 : 500;
            setTimeout(function(){
                jQuery("#loader").fadeOut();
            }, delay);
        });
    });

    jQuery(".owl-carousel").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        dots: true,
        animateOut: 'fadeOut'
    });

    if (jQuery(".news-detail__galery").length) {
        jQuery(".news-detail__galery").imagesLoaded( function() {
            jQuery('.news-detail__galery').masonry({
                itemSelector: '.news-detail__galery__item',
                columnWidth: '.news-detail__galery__sizer',
                percentPosition: true,
                horizontalOrder: true
            });
        });
    }

    if (jQuery(".news-index").length) {
        jQuery(".news-index").imagesLoaded( function() {
            jQuery('.grid').masonry({
                itemSelector: '.grid-item',
                columnWidth: '.grid-sizer',
                percentPosition: true,
                horizontalOrder: true
            });
        });
    }

    // Pause video on lightbox close for news focus
    if (jQuery("#focusVideo").length) {
        var video = jQuery("#focusVideo")[0];
        $('#news-detail__toggler__close').click(function() {
            if(!video.paused) video.pause();
        });
    }

    // Pause embed video on lightbox close for news focus
    /*if (jQuery("#embedVideo").length) {
        var embedVideo = $("#embedVideo").get(0);
        var embedVideoLocation = embedVideo.src;

        var embedWebsite = embedVideoLocation.split('www.')[1].split('.')[0];
    }*/

    // Menu button
    $('#btn-menu-open').click(function() {
        $('#main-menu').addClass('main-menu--active');

        $('body').css({
            'overflow': 'hidden'
        });
    });

    $('#btn-menu-close').click(function() {
        $('#main-menu').removeClass('main-menu--active');

        $('body').css({
            'overflow': 'unset'
        });
    });


    // ScrollTo allow to scroll
    $('.scroll-to').removeAttr("href");

    $('.scroll-to').click(function() {
        var scrollLink = $(this).attr('data-link');

        pomlo_app.scrollTo(scrollLink);
    });

});

jQuery(window).scroll(function() {
    pomlo_app.toggleHeaderStyle();
});

jQuery(window).resize(function() {
    pomlo_app.toggleHeaderStyle();
});
